<template>
  <div class="intro full">
    <van-nav-bar title="选择支付方式" >
      <template #left>
        <van-icon :size="24" color="#141212" name="arrow-left" slot="left" @click="exit()" />
      </template>
    </van-nav-bar>
    <div class="pw_top">
      <div class="item_top d_f ali_c">
        <span>支付剩余时间</span>
        <van-count-down class="time" :time="time" v-if="isCutDown"/>
        <span class="time" v-else>订单已失效</span>
      </div>
      <div class="item_b ali_c d_f">
        <div>应付金额：</div>
        <div class="price flex_1" v-if="totalDiscountPoints > 0"><span>￥{{price ? price*buyNum/100 : 0}}
        </span>￥{{price ? (price*buyNum - totalDiscountPoints * integralExchangeMoneyScale)/100 : 0}}
          <div class="jfdk">积分抵扣 减{{totalDiscountPoints * integralExchangeMoneyScale / 100}}</div> </div>
        <div class="price flex_1" v-else>￥{{price ? (price*buyNum/100).toFixed(2) : 0}}</div>
        <div class="d_f ali_c p_detail cur_p" v-if="!isOpen && info.length" @click="isOpen = true">
          <span>订单详情</span>
          <img src="./img/p_open.png" alt="">
        </div>
        <div class="d_f ali_c p_detail cur_p" v-if="isOpen" @click="isOpen = false">
          <span>收起详情</span>
          <img src="./img/p_close.png" alt="">
        </div>
      </div>
      <div class="p_d_out" v-if="isOpen">
        <div class="p_d">
          <div class="d_f j_s ali_c title">
            <div>{{info[0].commoditytName}}</div>
            <div class="num">X{{buyNum}}</div>
          </div>
          <template v-if="!isSnow">
            <div class="d_f j_s ali_c sub" v-for="(item,i) in info" :key="i" v-show="isRealName">
              <div class="name">{{item.visitorName}}</div>
              <div class="num" :class="totalDiscountPoints > 0 ? '' : flex_1">{{item.certificateNumber}}</div>
              <div class="price" v-if="totalDiscountPoints > 0"><div class="jfdk">积分抵扣 减{{item.totalDiscountPoints * integralExchangeMoneyScale / 100}}</div><span>￥{{price ? (price - item.totalDiscountPoints * integralExchangeMoneyScale)/100 : 0}}</span></div>
              <div class="price" v-else>￥{{price ? (price/100).toFixed(2) : 0}}</div>
            </div>
          </template>
          <!--滑雪票逻辑-->
          <div class="d_f j_s ali_c sub" v-else>
            <div class="name">{{info[0].visitorName}}</div>
            <div class="num flex_1">{{info[0].certificateNumber}}</div>
            <div class="price">￥{{price ? (price*buyNum/100).toFixed(2) : 0}}</div>
          </div>

          <div class="date d_f ali_c">
            <div>使用日期：{{info[0].beginDate}}</div>
            <div>时间：{{info[0].timeSlot}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="points" v-show="totalDiscountPoints > 0 || totalObtainPoint > 0">
      <div class="jf" v-if="totalObtainPoint > 0">+{{totalObtainPoint}}</div>
      <div class="jf reduce" v-if="totalDiscountPoints > 0">-{{totalDiscountPoints}}</div>
      <img src="../../../assets/img/icon-jf.png" alt="会员积分" />会员积分
    </div>
    <!--支付方式-->
    <div class="p_pay" v-if="isCutDown">
      <div class="pp_item d_f ali_c">
        <img src="./img/p_w.png" alt="">
        <span class="flex_1">微信支付</span>
        <div class="p_radio active d_f ali_c j_c">
          <div></div>
        </div>
      </div>
      <!--支付按钮-->
      <div class="p_btn cur_p t_a" @click="pay" v-if="isCutDown">
        <span v-if="totalDiscountPoints > 0">立即支付￥{{price ? (price*buyNum - totalDiscountPoints * integralExchangeMoneyScale)/100 : 0}}</span>
        <span v-else>立即支付￥{{price ? (price*buyNum/100).toFixed(2) : 0}}</span>
      </div>
    </div>
  </div>
</template>

<script>

  import { Dialog, Toast, NavBar, Icon, CountDown } from 'vant';
  import md5 from 'js-md5';
  export default {
    name: "payWay",
    components:{
      'van-nav-bar': NavBar,
      'van-count-down': CountDown,
      'van-icon': Icon,
    },
    data() {
      return {
        isOpen:false,//是否展开明细
        payway:1,//支付方式
        price:this.$route.query.price,
        code:this.$route.query.code,
        buyNum:this.$route.query.buyNum,
        apis:{
          detail:'/stlsOrder/findOrderDetail',
          queryOrderList:'/stlsOrder/queryOrderList',
          queryOrderStatus: 'stlsOrder/queryOrderStatus', //查询支付状态
          pay:"/stlsOrder/createPayInfo",
          getSysPointConfig: 'point/getSysPointConfig', // 查询积分比例
        },
        time:0,
        isCutDown:true,
        info:[{}],
        interVal:null,
        sellerId:'',
        isSnow:sessionStorage.getItem('isSnow'),
        totalObtainPoint: 0, // 获得积分
        integralExchangeMoneyScale: 1, // 使用积分比列
        totalDiscountPoints: 0, // 所有人使用的积分
        isRealName: 1 // 是否实名制
      }
    },
    created(){

      this.getShareInfo(
        this.$global.shareTitle,
        this.$global.visitUrl,
        this.$global.shareImage,
        this.$global.shareIntro,
      );
      this.getSysPointConfig();
      this.queryDetail();
      this.queryOrderList(); // 查询订单获得积分
      this.getStatus()
    },
    methods: {
      jsonInfo(c){
        var jsonData={}
        c=c.replace("{","").replace("}","");
        c=c.split(',')
        for(var i=0;i<c.length;i++){
          if(c[i].split('=').length === 2){
            jsonData[(c[i].split('=')[0]).replace(/\s+/g,"")]=(c[i].split('=')[1]).replace(/\s+/g,"")
          }else{
            let str = ''
            for(var j = 1;j<c[i].split('=').length;j++){
              str += (c[i].split('=')[j]).replace(/\s+/g,"")+'=';
            }
            jsonData[(c[i].split('=')[0]).replace(/\s+/g,"")]=(str.substring(0,str.length-1))
          }
        }
        return jsonData
      },
      pay(){
        let me = this;
        Toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: '支付中'
        });
        let price = 0;
        if(me.totalDiscountPoints > 0){
          price = me.price ? (me.price*me.buyNum - me.totalDiscountPoints * me.integralExchangeMoneyScale): 0;
        }else{
          price = me.price ? (me.price*me.buyNum) : 0;
        }
        me.$post2({
          // me.$postPay({
          url:me.apis.pay,
          params: {
            orderNumber: me.code,
            payType:'jsapi',
            // payType:'mweb',
            totalFee:price,
            token:me.$global.token
          }
        }).then(res => {
          let info = me.jsonInfo(res.data.formData);
          me.payWechat(info)
          /*let timestamp = me.$global.modelState ? sessionStorage.getItem('timestamp') : Number(sessionStorage.getItem('timestamp'))/1000;
          let signInfo = {
            Toast:info.appid,
            timestamp:timestamp,
            partnerid:info.mch_id,
            noncestr:info.nonce_str,
            package: "Sign=WXPay",
            prepayid:info.prepay_id,
          };
          console.log(signInfo)
          let stringA=me.sortAsc(signInfo);
          let stringSignTemp=stringA+"&key="+this.$global.appPayKey;
          let sign=md5(stringSignTemp).toUpperCase(); //注：MD5签名方式
          console.log(sign)
          let paySend = {
            appid:info.appid,
            timestamp:timestamp,
            partnerid:info.mch_id,
            noncestr:info.nonce_str,
            packageName: "Sign=WXPay",
            prepayid:info.prepay_id,
            paySign:sign,
            signType:'',
            iOSLink:'',
          };
          me.appPay(JSON.stringify(paySend));*/
        }).catch(err => {
          Toast(err.message);
          window.clearInterval(this.interVal);
          me.interVal = null;
        });
      },
      payWechat(info){
        let _t = this;
        if (typeof WeixinJSBridge == "undefined"){
          if( document.addEventListener ){
            document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
          }else if (document.attachEvent){
            document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
            document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
          }
        }else{
          onBridgeReady();
        }
        function onBridgeReady(){
          _t.interVal = setInterval(function () {
            _t.getStatus()
          },1000);
          WeixinJSBridge.invoke(
            'getBrandWCPayRequest', info,
            function(res){
              if(res.err_msg == "get_brand_wcpay_request:ok" ){
                setTimeout(function () {
                  Toast.clear();
                  window.clearInterval(_t.interVal);
                  clearInterval(_t.interVal);
                  _t.interVal = null;
                },10000)
                // 使用以上方式判断前端返回,微信团队郑重提示：
                //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              }
            });
        }
      },
      // 查询积分比列
      getSysPointConfig(){
        let me = this;
        me.$post({
          url:me.apis.getSysPointConfig,
          params: {}
        }).then(res => {
          // console.log(res)
          me.integralExchangeMoneyScale = res.data.integralExchangeMoneyScale
        });
      },
      sortAsc(o){
        var n = [];
        for(var k in o) n.push(k);
        n.sort();
        for(var i=0,str='';i<n.length;i++){
          var v = o[n[i]];
          if(v){
            if({}.toString.call(v)=='[object Object]')
              v = sortAsc(v);
            else if({}.toString.call(v)=='[object Array]')
              v = JSON.stringify(v).replace(/:/g,'=');
          }
          str+='&'+n[i]+'='+v;
        }
        return str.slice(1);
      },
      getStatus(){
        let me = this;
        me.$get({
          url:me.apis.queryOrderStatus,
          closeToast:true,
          params: {
            orderNumber: me.code,
            sortName: 'order_number',
            token:me.$global.token
          }
        }).then(res => {
          if(res.orderStatus == 4 || res.orderStatus == 5 || res.orderStatus == 7){
              Toast.clear();
              window.clearInterval(me.interVal);
              me.interVal = null;
              this.$router.push({
                path:'/paySuccess',
                query:{
                  orderNumber: me.code,
                  phone: res.reservationPersonTelephone,
                  sellerId: me.info[0].sellerId,
                  back:this.$route.query.back
                }
              })
          }
          // if(res.data.length){
          //   if(res.data[0].order.orderStatus == 4 || res.data[0].order.orderStatus == 5 || res.data[0].order.orderStatus == 7){
          //     Toast.clear();
          //     window.clearInterval(me.interVal);
          //     me.interVal = null;
          //     this.$router.push({
          //       path:'/paySuccess',
          //       query:{
          //         orderNumber:me.code,
          //         phone:res.data[0].order.reservationPersonTelephone,
          //         sellerId:me.info[0].sellerId
          //       }
          //     })
          //   }
          // }
        });
      },
      // 查询订单获得积分
      queryOrderList(){
        let me = this;
        me.$get({
          url:me.apis.queryOrderList,
          closeToast:true,
          params: {
            orderNumber: me.code,
            sortName: 'order_number',
            token:me.$global.token,
          }
        }).then(res => {
          // console.log(res)
          if(res.data.length){
            me.isRealName = res.data[0].order.isRealName
            for(let i in res.data){
              if(res.data[i].order.orderNumber == me.code){
                me.totalObtainPoint = res.data[i].order.totalObtainPoint
              }
            }
          }
        })
      },
      //查询详情
      queryDetail(){
        let me = this;
        me.$get({
          url:me.apis.detail,
          params: {
            orderNumber: me.code,
            token:me.$global.token,
          }
        }).then(res => {
          me.info = res;
          me.buyNum = me.info.length;
          me.sellerId = res[0].sellerId;
          if(me.price == 0){
            me.getStatus()
          }else{
            if(me.info[0].orderStatus === 0 || me.info[0].orderStatus === 1){
              let now = new Date().getTime();
              let order = new Date(me.$renderTime(me.info[0].creatorTime)).getTime();
              if((order + 15 * 60 * 1000)>now){
                me.time = (order + 15 * 60 * 1000) - now;
              }else{
                me.isCutDown = false;
              }
            }else{
              me.isCutDown = false;
            }
          }
          // me.totalObtainPoint = res
          // 查询全部使用积分
          let totalDiscountPoints = 0;
          for(let i in res){
            totalDiscountPoints += res[i].totalDiscountPoints
          }
          me.totalDiscountPoints = totalDiscountPoints
        });
      },
      exit(){
        Dialog.confirm({
          cancelButtonText: '放弃',
          confirmButtonText: '继续支付',
          message: '是否放弃本次支付？',
        })
          .then(() => {
          })
          .catch(() => {
            // this.$destroy('KeepAlive');
            window.clearInterval(this.interVal);
            clearInterval(this.interVal);
            sessionStorage.clear();
            sessionStorage.setItem('token',this.$global.token);
            sessionStorage.setItem('LOGIN_STATUS','2');
            this.interVal = null;
            if(this.$route.query.back == 'tc'){
              this.$router.go(-1)
            }else{
              this.$router.push('/tiketDetails/'+ this.sellerId + '?priceCalendarCount=1')
            }
          });
      },
    },
    beforeRouteLeave(to, from, next) {
      window.clearInterval(this.interVal);
      sessionStorage.clear();
      sessionStorage.setItem('LOGIN_STATUS','2');
      sessionStorage.setItem('token',this.$global.token);
      next();
    }
  }
</script>

<style scoped lang="scss">
  .p_btn{
    margin-top: 40px;
    height:40px;
    background:rgba(63,212,103,1);
    border:1px solid rgba(63,212,103,1);
    border-radius:5px;
    line-height: 40px;
    font-size:16px;

    font-weight:bold;
    color:rgba(255,255,255,1);;
  }
  .p_pay{
    .pp_item{
      .p_radio{
        width: 21px;
        height: 21px;
        border:1px solid rgba(204,204,204,1);
        border-radius: 50%;
        box-sizing: border-box;
        &.active{
          border-color: #3FD467;
          >div{
            box-sizing: border-box;
            border-radius: 50%;
            width: 15px;
            height: 15px;
            background-color: #3FD467;
          }
        }
      }
      .flex_1{
        font-size:14px;

        font-weight:500;
        color:rgba(20,18,18,1);
      }
      .p_z{
        width: 27px;
        margin-right: 17px;

      }
      .p_y{
        width: 32px;
        margin-right: 14px;

      }
      img{
        width: 29px;
        margin-right: 16px;
      }
      height:50px;
      background:rgba(255,255,255,1);
      border-radius:5px;
      margin-bottom: 13px;
      padding: 0 17px 0 13px;
    }
    padding: 22px 20px;
  }
  .pw_top{
    .p_d_out{
      .p_d{
        .date{
          padding: 0 13px;
          height:34px;
          line-height: 34px;
          background:rgba(244,244,244,1);
          border-top:1px solid rgba(204,204,204,1);
          font-size:12px;

          font-weight:bold;
          color:rgba(20,18,18,1);
          margin-top: 8px;
          >div{
            margin-right: 20px;
          }
        }
        .sub{
          .price{
            font-weight:bold;
            color:rgba(255,62,110,1);
            .jfdk{
              display: inline-block;
              vertical-align: middle;
              border: 1px solid #F62800;
              border-radius: 15px;
              padding: 0 5px;
              font-size: 8px;
              margin: -3px 0 0 5px;
            }
            span{
              color: #303030;
              margin-left: 5px;
            }
          }
          .name{
            width: 60px;
          }
          .num{
            margin-right: 10px;
          }
          margin-top: 15px;
          font-size:12px;

          font-weight:400;
          color:rgba(20,18,18,1);
          padding: 0 12px;
          &.mt_12{
            margin-top: 12px;
          }
        }
        .title{
          font-size:14px;

          font-weight:bold;
          color:rgba(20,18,18,1);
          padding: 0 12px;
        }
        background:rgba(255,255,255,1);
        border:1px solid rgba(204,204,204,1);
        border-radius:6px;
        padding: 12px 0 0 0;
        overflow: hidden;
      }
      padding:0 20px 16px 20px;
    }
    .item_b{
      .p_detail{
        img{
          width: 10px;
          margin-left: 8px;
        }
        font-size:12px;

        font-weight:400;
        color:rgba(87,87,87,1);
      }
      .price{
        color:rgba(255,56,126,1);
        margin-left: 15px;
        span{
          color: #A0A0A0;
          margin-right: 5px;
          text-decoration: line-through;
        }
        .jfdk{
          display: inline-block;
          vertical-align: middle;
          border: 1px solid #F62800;
          border-radius: 15px;
          padding: 0 5px;
          font-size: 8px;
          margin: -3px 0 0 5px;
        }
      }
      height: 43px;
      padding: 0 23px 0 26px;
      font-size:14px;

      font-weight:500;
      color:rgba(20,18,18,1);
    }
    .item_top{
      .time{
        margin-left: 20px;
      }
      border-bottom: 1px solid #E6E6E6;
      padding: 0 24px;
      height: 36px;
      font-size:12px;

      font-weight:400;
      color:rgba(20,18,18,1);
    }
    background-color: white;
  }
  .full{
    padding-top: 46px;
    background-color: #EEEEEE;
    .points{
      margin-top: 10px;
      background: #fff;
      padding: 10px 20px;
      box-sizing: border-box;
      line-height: 22px;
      font-size: 16px;
      img{
        height: 22px;
        vertical-align: text-bottom;
        margin-right: 10px;
      }
      .jf{
        float: right;
      }
      .reduce{
        color: #F62800;
      }
    }
  }

</style>



